
const getDefaultState = () => {
  return {
    companies: [],
    userId: null,
    currentCompanyId: '',
    ceoId: '',
    adminId: '',
    advisorId: '',
    clientId: '',
    leadId: '',
    calendarView: 'month-grid',
    supportId: '',
    multipleAccount: '',
    advisorDetails:{},
    noOfClientAccount: '',
    isMultipleVerified: '',
    currentTypeUser: '',
    awsConfig:{},
    role: '',
    TRCMData: '',
    TRCMSecrets:'', 
    isLoadingApp: false,
    termsAndConditions: null,
    termsAndConditionsInfo: null,
    trcmURL: null,
    showModalReadOnly: false,
    dateRangeClientReport: '',
    dateRangeCalendar: '',
    totalContractValue: '',
    itemsPerPage: {
      options: [
        {
          title: '10',
          command: '10',
        },
        {
          title: '50',
          command: '50',
        },
        {
          title: '100',
          command: '100',
        },
      ],
      values: {
        default: '10',
        schwab: '10',
        annuity: '10',
        advisorDashboard: '10',
        listOfHouseholds: '10',
        superAdminDashboard: '10',
        superAdminListAdvisors: '10',
      },
    },
    selectFilter: {
      options: [
        {
          title: 'Past year',
          command: 'year',
        },
        {
          title: 'Past month',
          command: 'month',
        },
        {
          title: 'Past quarter',
          command: 'quarter',
        },
      ],
      values: {
        default: 'month',
        opportunitiesBlock: 'month',
        pipeLine: 'month',
        countLeeds: 'month',
        numberLeedsFilledData: 'month',
        numberLeedsConverted: 'month',
        numberLeedsClosed: 'month',
      },
    },
    dialog: {
      showDialog: {
        changeName: false,
        changePassword: false,
        shareFileEmailDialog: false,
        previewModal: false,
        annuityModal: false,
        moreOtherInfo: false,
        moreOwnerInfo: false,
        changePhone: false,
        exportSucces: false,
        modalContact: false,
        newLead: false,
        modalNewTable: false,
        modalSucces: false,
        modalTerms: false,
        modalAddAnnuityIndex: false,
        modalInvestmentPackage: false,
        addChildOpportunity: false,
        modalSummaryInfo: false,
        restoreDraft: false,
        newContract: false,
        modalUploadDocuments: false,
      },
    },
    sortMembers: {
      orderBy: 'created_at',
      sortedBy: 'descending',
    },
    memberId: null,
    pdfRegion: '',
    showDialogReloadPage: false,
    needUpdateContent: null,
    docShare: null,
    contact: null,
    previewFile: null,
    opportunityId: null,
    onlyMyMember: false,
    ownerMember: null,
    onlyMyLead: false,
    ownerLead: null,
    onlyMyPreLead: false,
    ownerPreLead: null,
    summaryInfoMemberId: null,
    uploadMedicareDocsStatus: null,
    uploadPropertyDocsStatus: null,
    uploadInvestmentDocsStatus: null,
    uploadLifeDocsStatus: null,
    uploadSocialDocsStatus: null,
    collectionUploadMedia: null,
    otpType: null,
    pageClientsHelp: null,
  }
}

const state = getDefaultState()

const actions = {
  resetState({ commit }) {
    commit('resetState')
  },
}

const mutations = {
  setNoAccount(state, value) {
    state.noOfClientAccount = value
  },
    setTRCMSecrets(state, value) {
    state.TRCMSecrets = value
  },
  setMultipleAccountVerified(state, value) {
    state.isMultipleVerified = value
  },
  setDestinationItemsPerPage(state, data) {
    const { destination, value } = data
    state.itemsPerPage.values[destination] = value
  },

  setDestinationSelectFilter(state, data) {
    const { destination, value } = data
    state.selectFilter.values[destination] = value
  },

  setShowModal(state, data) {
    const { destination, value } = data
    state.dialog.showDialog[destination] = value
  },

  setAdvisorDetails(state, value){
    state.advisorDetails = value
  },
  
  setSortMembers(state, data) {
    const { orderBy, sortedBy } = data
    state.sortMembers.orderBy = orderBy
    state.sortMembers.sortedBy = sortedBy
  },

  setShowDialogRealodPage(state, value) {
    state.showDialogReloadPage = value
  },

  setPdfRegion(state, value) {
    state.pdfRegion = value
  },

  setMultipleAccount(state, value) {
    state.multipleAccount = value
  },

  setNoOfClientAccount(state, value) {
    state.noOfClientAccount = value
  },

  setDocShare(state, value) {
    state.docShare = value
  },

  setNeedUpdateContent(state, value) {
    state.needUpdateContent = value
  },

  setMemberId(state, value) {
    state.memberId = value
  },

  setContact(state, value) {
    state.contact = value
  },

  setCompanies(state, value) {
    state.companies = value
  },

  setCurrentCompanyId(state, value) {
    state.currentCompanyId = value
  },

  setAdminId(state, value) {
    state.adminId = value
  },

  setCeoId(state, value) {
    state.ceoId = value
  },

  setAdvisorId(state, value) {
    state.advisorId = value
  },

  setClientId(state, value) {
    state.clientId = value
  },

  setLeadId(state, value) {
    state.leadId = value
  },

  setSupportId(state, value) {
    state.supportId = value
  },

  setResult(state, value) {
    state.Rersult = value
  },

  setCurrentTypeUser(state, value) {
    state.currentTypeUser = value
  },

  setIsClientPreview(state, value) {
    state.isClientPreview = value
  },

  setRole(state, value) {
    state.role = value
  },

  setTrcmData(state, value) {
    state.TRCMData = value
  },

  setUserId(state, value) {
    state.userId = value
  },

  setIsLoadingApp(state, value) {
    state.isLoadingApp = value
  },

  setTermsAndConditions(state, value) {
    state.termsAndConditions = value
  },

  setTermsAndConditionsInfo(state, value) {
    state.termsAndConditionsInfo = value
  },

  setTrcmURL(state, value) {
    state.trcmURL = value
  },

  setDateRangeClientReport(state, value) {
    state.dateRangeClientReport = value
  },
  setDateRangeCalendar(state, value) {
    state.dateRangeCalendar = value
  },
  setPreviewFile(state, value) {
    state.previewFile = value
  },

  setOnlyMyMember(state, value) {
    state.onlyMyMember = value
  },

  setOwnerMember(state, value) {
    state.ownerMember = value
  },

  setOnlyMyLead(state, value) {
    state.onlyMyLead = value
  },

  setOwnerLead(state, value) {
    state.ownerLead = value
  },

  setOnlyMyPreLead(state, value) {
    state.onlyMyPreLead = value
  },

  setOwnerPreLead(state, value) {
    state.ownerPreLead = value
  },

  setOpportunityId(state, value) {
    state.opportunityId = value
  },

  setShowModalReadOnly(state, value) {
    state.showModalReadOnly = value
  },

  setSummaryInfoMemberId(state, value) {
    state.summaryInfoMemberId = value
  },

  setUploadMedicareDocsStatus(state, value) {
    state.uploadMedicareDocsStatus = value
  },

  setUploadPropertyDocsStatus(state, value) {
    state.uploadPropertyDocsStatus = value
  },

  setUploadInvestmentDocsStatus(state, value) {
    state.uploadInvestmentDocsStatus = value
  },

  setUploadLifeDocsStatus(state, value) {
    state.uploadLifeDocsStatus = value
  },

  setUploadSocialDocsStatus(state, value) {
    state.uploadSocialDocsStatus = value
  },

  setCollectionUploadMedia(state, value) {
    state.collectionUploadMedia = value
  },

  setPageClientsHelp(state, value) {
    state.pageClientsHelp = value
  },

  setOtpType(state, value) {
    state.otpType = value
  },

  setCalendarView(state, value) {
    state.calendarView = value
  },

  setTotalContractValue(state, value) {
    state.totalContractValue = value
  },

  resetState(state) {
    Object.assign(state, getDefaultState())
  },

  setAwsConfig(state, value) {
    state.awsConfig = value
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
}
