<template>
  <SwdDropDown :options="actionsOptions" @select="handleSelect">
    <template #titleDropDown>
      <span
        class="cursor-pointer bg-white rounded flex justify-center items-center w-[28px] h-[28px]"
        :class="{ 'border border-primary': pageDetails }"
      >
        <el-icon :color="pageDetails ? '#042D52' : '#C7CFE3'" class="rotate-90">
          <MoreFilled />
        </el-icon>
      </span>
    </template>
  </SwdDropDown>
  <el-dialog v-model="dialogFormVisible" title="Confirmation" width="50%" :before-close="closeDialog" destroy-on-close>
    <div class="p-4">
      <h3 class="flex justify-center">
        <p v-if="dialogAction === 'client-login'">Are you sure you want to sign in as the client?</p>
        <p v-if="dialogAction === 'sendInvitation'">Are you sure you want to send an invitation email?</p>
      </h3>
    </div>

    <div style="border-bottom: 1px solid #e4e7ed; margin-top: 20px"></div>

    <div class="pt-5 flex justify-end">
      <el-button @click="handleClose()">Cancel</el-button>
      <el-button type="primary" :loading="loading" @click="confirmAction()">Confirm</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { ref } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { MoreFilled } from '@element-plus/icons-vue'
import { useStore } from 'vuex'
import { useGetDetails } from '@/hooks/use-get-details'
import ROLE from '../../enums/roles'
import { useWelcomeEmail } from '@/api/authentication/use-welcome-email'

const allAvailibleOptions = {
  1: {
    title: 'More Details',
    command: 'details',
  },
  2: {
    title: 'Basic Information',
    command: 'basic-information',
  },
  3: { title: 'Blueprint Report', command: 'blueprint-report' },
  // 4: { title: 'Progress Report', command: 'client-report' },
  5: { title: 'Onboarding', command: 'onboarding' },
  6: { title: 'Accounts', command: 'assets-accounts' },
  //  7: { title: 'Account Holdings', command: 'assets-consolidations' },
  8: { title: 'Contacts', command: 'opportunity-contact' },
  9: { title: 'Sign In as Client', command: 'client-login' },
  10: { title: 'Send Invitation', command: 'sendInvitation' },
}

const optionsPerStepAndType = {
  'widget-details-client': [6],
  'widget-details-support': [6],
  'widget-details-prospect': [5, 6],
   client: [1, 2, 6, 8, 9, 10],
  'prospect@step-0': [1, 5, 6, 8],
  'prospect@step-1': [1, 2, 5, 6, 8],
  'prospect@step-2': [1, 2, 5, 6, 8],
  'prospect@step-3': [1, 2, 5, 6, 8],
  'prospect@step-4': [1, 2, 5, 6, 8],
  'prospect@step-5': [1, 2, 5, 6, 8],
  'prospect@step-6': [1, 2, 5, 6, 8],
  onboarding: [5, 8],
}

function getClientStepHash(user, pageDetails, type = null) {
  switch (true) {
    case type !== null && type === ROLE.SUPPORT:
      return 'widget-details-support'
    case type !== null && type === ROLE.CLIENT:
      return 'widget-details-client'
    case pageDetails && user.type === ROLE.PROSPECT:
      return 'widget-details-prospect'
    case user.type === ROLE.CLIENT:
      return 'client'
    case user.type === ROLE.PROSPECT && user.step === 'basic':
      return 'prospect@step-1'
    case user.type === ROLE.PROSPECT && user.step === 'assets_income':
      return 'prospect@step-2'
    case user.type === ROLE.PROSPECT && user.step === 'monthly_expense':
      return 'prospect@step-3'
    case user.type === ROLE.PROSPECT && user.step === 'assets_accounts':
      return 'prospect@step-4'
    case user.type === ROLE.PROSPECT && user.step === 'assets_consolidation':
      return 'prospect@step-5'
    case user.type === ROLE.PROSPECT && user.step === 'member_report':
      return 'prospect@step-6'
    default:
      return 'onboarding'
  }
}

function routerForStep(step) {
  switch (true) {
    case step === 'default':
      return 'basic-information'
    case step === 'basic':
      return 'assets-information'
    case step === 'assets_income':
      return 'monthly-expense'
    case step === 'monthly_expense':
      return 'assets-account'
    case step === 'assets_accounts':
      return 'assets-account'
    case step === 'assets_consolidation':
      return 'stresstest'
    default:
      return 'basic-information'
  }
}

function buildOptions(user, pageDetails, type) {
  const hash = getClientStepHash(user, pageDetails, type)
  const optionsIds = optionsPerStepAndType[hash]

  return optionsIds.map((id) => allAvailibleOptions[id])
}

export default {
  name: 'SwdMemberActions',
  components: {
    MoreFilled,
  },
  props: {
    user: {
      type: Object,
      require: true,
      default: () => {},
    },
    pageDetails: {
      type: Boolean,
      require: false,
      default: false,
    },
  },
  setup(props) {
    const router = useRouter()
    const route = useRoute()
    const store = useStore()
    const dialogFormVisible = ref(false)
    const dialogAction = ref(null) // Track the action that triggered the dialog
    const loading = ref(false);
    const { getDetails } = useGetDetails()

    const actionsOptions = buildOptions(props.user, props.pageDetails, route.meta.type)

    const handleSelect = (command) => {
      const actionHandler = actionsMap[command]
      actionHandler()
      dialogAction.value = command
      dialogFormVisible.value = true
    }

    const actionsMap = {
      details: () => details(),

      'basic-information': () =>
        router.push({
          name: `${route.meta.type || 'default-type'}/basic-information`,
          params: { id: props.user.id },
        }),

      'blueprint-report': () =>
        router.push({
          name: `${route.meta.type || 'default-type'}/blueprint-report`,
          params: { id: props.user.id },
        }),

      'client-report': () =>
        router.push({
          name: `${route.meta.type || 'default-type'}/client-reports-list`,
          params: { id: props.user.id },
        }),

      onboarding: () => getOnboarding(),

      sendInvitation: () => sendInvitationEmail(),

      'client-login': () => clientLogin(),

      'assets-accounts': () =>
        router.push({
          name: `${route.meta.type || 'default-type'}/asset-accounts`,
          params: { id: props.user.id },
        }),

      'assets-consolidations': () =>
        router.push({
          name: `${route.meta.type || 'default-type'}/assets-consolidations`,
          params: { id: props.user.id },
        }),

      'opportunity-contact': () =>
        router.push({
          name: `${route.meta.type || 'default-type'}/opportunity-contact`,
          params: { id: props.user.id },
        }),
    }
    const clientLogin = async () => {
      if (dialogFormVisible.value) {
        try {
          store.commit('globalComponents/setCurrentTypeUser', ROLE.ADVISOR)
          store.commit('globalComponents/setIsClientPreview', true)
          store.commit('globalComponents/setAdvisorId', props.user.owner_id)
          router.push({ name: 'client-preview/member-details', params: { id: props.user.id } })
        } catch (error) {
          console.error('Error during client login:', error)
          store.commit('globalComponents/setIsLoadingApp', false)
        }
      }
    }

    const sendInvitationEmail = async () => {
      if (dialogFormVisible.value) {
        const { sendWelcomeEmail } = useWelcomeEmail(props.user.id)
        await sendWelcomeEmail()
      }
    }
    const confirmAction = async () => {
      loading.value = true

      try {
        if (dialogAction.value === 'client-login') {
          await clientLogin()
        } else if (dialogAction.value === 'sendInvitation') {
          await sendInvitationEmail()
        }
      } catch (error) {
        console.error('Error occurred:', error)
      } finally {
        loading.value = false
        handleClose()
      }
    }
    const handleClose = () => {
      dialogFormVisible.value = false
      dialogAction.value = null
    }
    const getOnboarding = () => {
      store.commit('globalComponents/setAdvisorId', props.user.owner_id)
      router.push({
        name: routerForStep(props.user.step),
        params: { id: props.user.id },
      })
    }

    const details = () => {
      getDetails({ member: props.user })
    }

    return {
      actionsOptions,
      handleSelect,
      confirmAction,
      handleClose,
      dialogFormVisible,
      loading,
      dialogAction,
    }
  },
}
</script>
